<template>
    <div class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
        <div class="grid justify-content-center p-2 lg:p-0" style="min-width:80%">
            <div class="col-12 text-center">
                <img :src="publicPath+'layout/images/logo.png'" alt="MZSpace logo" class="mb-5" style="width:120px; height:120px;">
            </div>
            <div class="col-12 xl:col-6" style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, var(--primary-color), rgba(33, 150, 243, 0) 30%);">
                <div class="h-full w-full m-0 py-7 px-4" style="border-radius:53px; background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0));">
                    <div class="text-center mb-5">
                        <div class="text-900 text-3xl font-medium mb-3">欢迎使用MZSpace！</div>
                    </div>
                
                    <div class="w-full md:w-10 mx-auto">
                        <label for="username1" class="block text-900 text-xl font-medium mb-2">用户名</label>
                        <InputText id="username1" v-model="username" type="text" class="w-full mb-3" placeholder="用户名" style="padding:1rem;" />
                
                        <label for="password1" class="block text-900 font-medium text-xl mb-2">密码</label>
                        <Password id="password1" :feedback="false" v-model="password" placeholder="密码" :toggleMask="true" class="w-full mb-3" inputClass="w-full" inputStyle="padding:1rem"></Password>
                
                        <div class="flex align-items-center justify-content-between mb-5">
                            <div class="flex align-items-center">
                                <Checkbox id="rememberme1" v-model="checked" :binary="true" class="mr-2"></Checkbox>
                                <label for="rememberme1">保持登录7天</label>
                            </div>
                            <a class="font-medium no-underline ml-2 text-right cursor-pointer" style="color: var(--primary-color)">忘记密码?</a>
                        </div>
                        <Button @click="login"  label="登录" class="w-full p-3 text-xl"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Service from '../service/Service';
import md5 from 'js-md5';
export default {
    data() {
        return {
            publicPath: process.env.BASE_URL,
            username: '',
            password: '',
            checked: false
        }
    },
    created() {
        this.Service = new Service();
    },
    mounted(){
        localStorage.clear();
		sessionStorage.clear();
    },
    computed: {
    },
    methods:{
        login(){
            var d = new Date();
            var time = d.getTime();
            var url='login.php?action=login&time='+time;
            const formData = new FormData();
            formData.append('username', this.username);
            formData.append('password', md5('mzspace'+time+md5(this.password)));
            if(this.checked){
                formData.append('keepLogin', 'true');
            }
            this.Service.sendHttp(url,'POST',formData).then(res=>res.json()).then(data=>{
                if(data.result=='success'){
                    if(this.checked){
                        localStorage.setItem("keepLogin",'true');
                    }
                    localStorage.setItem("token",data.token);
                    localStorage.setItem("user",JSON.stringify(data.user));
                    localStorage.setItem("stores",JSON.stringify(data.stores));
                    this.$router.push({name:"dashboard"});
                }
                else{
                    alert('登录失败！');
                }
            });
        },
    }
}
</script>